menu {
  width: 100px;
  height: 100%;
  background: #19162c;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

menu img {
  width: 3em;
  padding-top: 30px;
}

menu ul {
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

menu ul li {
  list-style: none;
  margin: 20px 0;
  position: relative;

  width: 100%;
  text-align: center;
}

menu ul li a {
  font-size: 26px;
  color: #8c8a95;
  text-decoration: none;
  transition: all 0.3s;
}

menu ul li a::before {
  position: absolute;
  content: '';
  top: 5px;
  left: -20px;
  width: 0px;
  height: 20px;
  background: #b1147d;
  border-radius: 50%;
}

menu ul li:hover a {
  color: #b1147d;
}

menu ul li:hover a::before {
  width: 20px;
  transition: all 0.3s;
}

menu ul li.active a {
  color: #b1147d;
}

menu ul li.active a::before {
  width: 20px;
  transition: all 0.3s;
}

.lastMenu {
  margin-top: auto;
}

@media only screen and (max-width: 525px) {
  menu {
    min-width: 68px;
  }
}
